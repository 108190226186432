<template>
    <div>
        <v-dialog v-model="dialog" persistent max-width="1000px">
            <v-card>
                <v-card-title>
                    <span class="text-h5">Duplicate Employee Roster</span>
                </v-card-title>
                <v-card-text>
                    <v-container>
                        <v-row>
                            <v-col cols="12" sm="3" md="3" v-for="item in departments">
                                <v-checkbox v-model="department_id" :label="item.name" :value="item.id"
                                    style="font-size: 12px !important"></v-checkbox>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <label for="">From</label><br>
                                <v-date-picker v-model="from_month" type="month"></v-date-picker>
                            </v-col>
                            <v-col cols="12" sm="6" md="6" class="text-center">
                                <label for="">To</label><br>
                                <v-date-picker v-model="to_month" type="month"></v-date-picker>
                            </v-col>

                        </v-row>
                    </v-container>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="saveDuplicate">
                        Save
                    </v-btn>
                    <v-btn color="red darken-1" text @click="() => this.$emit('success', false)">
                        Close
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
export default {
    props: {
        dialog: ""
    },
    data() {
        return {
            departments: [],
            department_id: [],
            from_month: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).slice(0, 7),
            to_month: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10).slice(0, 7),
            server_errors: {
                from_month: "",
                to_month: ""
            }
        }
    },
    methods: {
        saveDuplicate() {
            const item = {
                from_month: this.from_month,
                to_month: this.to_month,
                department_id: this.department_id
            }
            this.$emit('success', false);
            this.$emit('save', true);
            this.$axios
                .post(`company/duplicate/roster/employee/clockin`, item)
                .then((res) => {
                    this.$emit('done', res.data.data);
                    this.$emit('save', false);
                })
                .catch((error) => {
                    this.$emit('save', false);
                    if (error.response.status === 422) {
                        let obj = error.response.data.errors;
                        if (!obj) {
                            obj = error.response.data;
                            let res = "";
                            for (let [key, value] of Object.entries(obj)) {
                                res = value;
                            }
                            this.$notification.ShowErrors("top-right", "danger", 3000, res);
                        } else {
                            for (let [key, value] of Object.entries(obj)) {
                                this.server_errors[key] = value[0];
                                this.$notification.ShowErrors(
                                    "top-right",
                                    "danger",
                                    3000,
                                    (this.server_errors[key] = value[0])
                                );
                            }
                        }
                    }
                });
        },
        fetchDepartment() {
            this.$axios.get(`company/list/departments`).then((res) => {
                this.isLoading = false;
                if (res.status === 200) {
                    this.departments = res.data.data;
                }
            });
        }
    },
    created() {
        this.fetchDepartment();
    },
}
</script>

<style lang="scss" scoped>
label {
    font-size: 12px !important;
}
</style>